import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import '@/assets/tailwind.css';
// import '@/helpers/anchoring.js';



import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);





import DirectusSDK from '@directus/sdk-js';


Vue.config.productionTip = false;


// -- -- globally register all base components -- --
// import upperFirst from 'lodash/upperFirst'; // use if filename is dash-case
// import camelCase from 'lodash/camelCase'; // use if filename is dash-case
// Require in a base component context
const requireComponent = require.context('./components/bases', false, /Base[\w-]+\.vue$/);
requireComponent.keys().forEach((fileName) => {
	// Get component config
	const componentConfig = requireComponent(fileName);
	// Get PascalCase name of component (if filename is dash-case. For e.g.: 'base-button.vue')
	// const componentName = upperFirst( camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')) );
	const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '');
	// Register component globally
	Vue.component(componentName, componentConfig.default || componentConfig);
});


const client = new DirectusSDK({
	url: 'https://podinthehood.com/cms/public',
	project: 'podinthehood',
	storage: process.client && window.localStorage,
});

Object.defineProperties(Vue.prototype, {
	'$directus': { value: client },
});



new Vue({
	router,
	render: h => h(App),
}).$mount('#app');
