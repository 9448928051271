<template>
    <header class="TheNavbar">
        <img
            class="company-logo regular"
            src="../../assets/logo.png"
            alt="Pod in the Hood"
        />
        <div v-if="mobileView" class="hamburger-menu-wrapper">
            <img
                src="../../assets/open-menu.svg"
                class="hamburger-menu"
                @click="handleMobileIpadViewNavbar()"
            />
        </div>

        <nav
            class="container text-center flex flex-row relative justify-center font-bebas text-lg"
            :class="`${mobileView ? 'responsive-nav hidden' : 'nav-container'}`"
        >
            <template v-for="item in navItems" class="yohoo">
                <button
                    v-if="item.sectionEl"
                    :key="item.id"
                    class="nav-item text-lg"
                    :class="{
                        active: activeNav === item.id,
                    }"
                    @click="handleNavItemClick(item)"
                >
                    {{ item.label }}
                </button>
                
                <router-link
                    v-else
                    :key="item.id"
                    class="nav-item text-lg"
                    :class="{
                        active: $route.path === `/${item.id}`,
                    }"
                    :to="item.id ? `/#${item.id}` : '/'"
                    >{{ item.label }}</router-link
                >
                
            </template>
            <a class="nav-item text-lg" href="https://shop.podinthehood.com"  target="_blank" @click="handleNavItemClick()">P.I.T.H. Store</a>
        </nav>
    </header>
</template>


<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
    name: "TheNavbar",
    data() {
        return {
            navItems: [],
            activeNav: "banner",
            scrollIndex: 0,
            mobileView: false,
            windowWidth: window.innerWidth,
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
    },
    mounted() {
        if (this.$router.history.current.name === "Home") {
            this.routeEnterHome();
        } else {
            this.routeLeaveHome();
        }

        this.handleHomeHashNavigation();
        this.handleView();
    },
    methods: {
        handleMobileIpadViewNavbar() {
            $(".responsive-nav").toggleClass("hidden");
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
            this.handleView();
        },
        handleView() {
            if (this.windowWidth <= 1024) {
                console.log("mobileIpad mode");
                this.mobileView = true;
            } else {
                this.mobileView = false;
            }
        },
        createScrollTrigger(triggerID, el, headerHeight) {
            ScrollTrigger.create({
                trigger: el,
                start: `top ${headerHeight + 1}px`,
                // markers: true,
                end: `bottom ${headerHeight + 1}px`,
                onEnter: (self) => {
                    this.activeNav = triggerID;
                },
                onEnterBack: (self) => {
                    this.activeNav = triggerID;
                },
            });
        },
        handleNavItemClick(navItem) {
            if (navItem) {
                this.gsapScrollTo({ y: navItem.sectionEl });
            }
            
            if (this.windowWidth <= 1024) {
                $(".responsive-nav").toggleClass("hidden");
            } else {
                return;
            }
        },

        handleHomeHashNavigation() {
 
            const hash = window.location.hash.slice(1);
            const found = this.navItems.find((navItem) => navItem.id === hash);

            if (!hash) {
                this.gsapScrollTo({ y: 0 });
            } else if (hash && found) {
                this.gsapScrollTo({ y: found.sectionEl });
                window.history.replaceState("", "", "/");
            }
            if (this.windowWidth <= 1024) {
                    $(".responsive-nav").toggleClass("hidden");
                } else {
                    return;
                }
        },

        gsapScrollTo({ y = 0, duration = 0.45 } = {}) {
            const headerHeight = this.$el.offsetHeight;
            gsap.to(window, {
                duration,
                scrollTo: {
                    y,
                    offsetY: headerHeight,
                },
                ease: "power2",
            });
        },

        routeLeaveHome() {
            this.navItems = [
                {
                    id: "",
                    label: "Home",
                    sectionEl: null,
                },
                {
                    id: "philosophy",
                    label: "Philosophy",
                    sectionEl: null,
                },
                {
                    id: "programmes-and-workshop",
                    label: "Programmes & Workshop",
                    sectionEl: null,
                },
                {
                    id: "admission",
                    label: "Admission",
                    sectionEl: null,
                },
                {
                    id: "contact-us",
                    label: "Contact Us",
                    sectionEl: null,
                },
                
                
            ];
        },
        routeEnterHome() {
            const headerHeight = this.$el.offsetHeight;
            this.navItems = [];

            document.querySelectorAll("[data-root-section]").forEach((el) => {
                const sectionId = el.getAttribute("data-anchor-id");
                const sectionLabel = el.getAttribute("data-section-label");

                this.navItems.push({
                    id: sectionId,
                    label: sectionLabel,
                    sectionEl: el,
                });
                this.createScrollTrigger(sectionId, el, headerHeight);
            });
        },
    },
    watch: {
        $route(to, from) {
            if (to.name === "Home") {
                this.routeEnterHome();
                this.$nextTick(() => {
                    this.handleHomeHashNavigation();
                });
            } else {
                this.routeLeaveHome();
                this.gsapScrollTo({ y: 0, duration: 0 });
            }
        },
    },
};
</script>


<style scoped lang="less">
.TheNavbar {
    @apply flex;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    height: 56px;
    border-bottom: 1px solid hsla(0, 0%, 17%, 0.1);

    .nav-item {
        font-weight: bold;
        color: #2c3e50;
        text-transform: uppercase;
        // padding-bottom: 5px;
        @apply mr-6 self-center;
        margin-top: 13px;
        padding-bottom: 11px;

        &:last-child {
            // @apply mr-0;
        }

        &.active {
            border-bottom: 4px solid var(--primaryColor);
            // color: var(--primaryColor);
        }

        &:focus {
            outline: none;
        }
        &:hover {
            border-bottom: 4px solid var(--primaryColor);
        }
    }
}

.company-logo {
    position: absolute;
    left: 10%;
    width: auto;
    height: 53px;
    top: 0;
    padding: 6px 0;
    // box-shadow: 0px 3px 6px hsla(0, 0%, 17%, 0.1);

    &.regular {
        // width: 90px;
    }
    &.tiny {
        // width: 53px;
    }
}
.responsive-nav {
    right: 0;
    background-color: white;
    top: 56px;
    position: absolute;
    transition: all 0.5s linear;
    box-shadow: 0px 3px 6px hsla(0, 0%, 17%, 0.5);
    width: 250px;
    margin: 3px 8px 0 0;
    display: grid;
    justify-items: end;
    padding-bottom: 16px;

    ::after {
        content: "";
        position: absolute;
        top: 0;
        right: -10px;
        box-sizing: border-box;
        border: 0.5em solid black;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        transform: rotate(135deg);
        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.03);
    }
    .nav-item {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
}

.hidden {
    display: none;
}

.hamburger-menu {
    margin-left: auto;
    cursor: pointer;
}
@media (min-width: 280px) and (max-width: 480px) {
    .hamburger-menu-wrapper {
        padding: 14px;
    }

    .TheNavbar {
        display: block;
    }

    .company-logo {
        z-index: 9999;
        left: 5%;
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    .TheNavbar {
        display: block;
    }

    .hamburger-menu-wrapper {
        padding: 14px;
    }
    .company-logo {
        left: 5%;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .TheNavbar {
        display: block;
    }

    .hamburger-menu-wrapper {
        padding: 14px;
    }
    .company-logo {
        left: 5%;
    }
}
</style>
