<template>
    <section
        class="SectionBanner"
        data-root-section
        data-section-label="Home"
        data-anchor-id="banner"
    >
        <BaseSpinner v-if="isLoading" class="h-full max-h-inherit" />

        <BaseSwiper v-if="!isLoading && bannerContent.length > 0" :hasNavButtons="true">
            <article
                class="swiper-slide inline-block"
                v-for="banner in bannerContent"
                :key="banner.id"
                :style="{
					'background-image': `url(${banner.banner_image.data.thumbnails[7].url})`,
				}"
            >
                <div
                    class="banner-content p-6 flex flex-col justify-center items-center text-white"
                >
                    <div class="flex flex-col container items-center px-10">
                        <h1 class="banner-title font-bold font-bebas">{{banner.title}}</h1>
                        <div class="rectangle-white mt-5"></div>
                        <p
                            class="banner-desc max-w-2xl text-center mt-5 text-xl"
                        >{{banner.description}}</p>
                        <div class="mt-5" v-if="banner.cta_text && banner.cta_link">
                            <router-link
                                v-if="banner.cta_link.startsWith('/')"
                                class="button text-sm uppercase"
                                :to="banner.cta_link"
                            >{{banner.cta_text}}</router-link>

                            <a
                                v-else
                                class="button text-sm uppercase"
                                :href="banner.cta_link"
                                @click="handleBannerAnchorClick(banner.cta_link, $event)"
                                target="_blank" 
                            >{{banner.cta_text}}</a>
                        </div>
                    </div>
                </div>
            </article>
        </BaseSwiper>
    </section>
</template>


<script>
import { gsap } from "gsap";

export default {
    name: "SectionBanner",
    data() {
        return {
            bannerContent: [],
            isLoading: true,
        };
    },
    async created() {
        const bannerContent = (
            await this.$directus.getItems("hero_banner", {
                // fields: "*,banner_image.data.full_url",
                fields: [
                    "id",
                    "title",
                    "description",
                    "cta_text",
                    "cta_link",
                    "banner_image.data.full_url",
                ],
            })
        ).data;

        this.isLoading = false;
        this.bannerContent = bannerContent;
    },
    methods: {
        handleBannerAnchorClick(url, event) {
            if (url.startsWith("#")) {
                const sectionEl = document.querySelector(
                    '[data-anchor-id="contact-us"]'
                );
                if (sectionEl) {
                    event.preventDefault();
                    this.gsapScrollTo({ y: sectionEl });
                }
            }
        },

        gsapScrollTo({ y = 0, duration = 0.45 } = {}) {
            const headerHeight = document.querySelector(".TheNavbar")
                .offsetHeight;

            gsap.to(window, {
                duration,
                scrollTo: {
                    y,
                    offsetY: headerHeight,
                },
                ease: "power2",
            });
        },
    },
};
</script>


<style lang="less" scoped>
.SectionBanner {
    // --bannerHeight: 550px;
    --bannerHeight: 650px;
    max-height: 100vh;
    height: var(--bannerHeight);
}
.swiper-slide {
    background: center center/cover no-repeat;
    height: var(--bannerHeight);
    max-height: 100vh;
}
.banner-title {
    font-size: 54px;
}

.banner-content {
    background-color: fade(black, 50%);
    height: inherit;
    max-height: 100vh;
}
.button {
    --bgColor: white;
    --btnTextColor: var(--textColor);
}
@media (min-width: 280px) and (max-width: 480px) {
    .SectionBanner {
        // --bannerHeight: 450px;
        max-height: 100vh;
        // height: var(--bannerHeight);
    }
    .banner-title {
        @apply text-4xl text-center leading-none;
    }
    .banner-content {
        @apply p-0;
        p {
            @apply text-sm;
        }
    }
    
}
@media (min-width: 768px) and (max-width: 1024px) {
    .banner-title {
        @apply text-4xl text-center leading-none;
    }
}
</style>
