<template>
    <div class="home ">
        <SectionBanner />
        <SectionPhilosophy />
        <SectionProgrammesAndWorkshop />
        <SectionAdmission />
        <SectionContactUs />
    </div>
</template>

<script>
import SectionBanner from "@/components/sections/SectionBanner.vue";
import SectionPhilosophy from "@/components/sections/SectionPhilosophy.vue";
import SectionProgrammesAndWorkshop from "@/components/sections/SectionProgrammesAndWorkshop.vue";
import SectionAdmission from "@/components/sections/SectionAdmission.vue";
import SectionContactUs from "@/components/sections/SectionContactUs.vue";

export default {
    name: "Home",
    components: {
        SectionBanner,
        SectionPhilosophy,
        SectionProgrammesAndWorkshop,
        SectionAdmission,
        SectionContactUs,
    },
    
};
</script>
<style scoped lang="less">
.home{
    background-color: #eceaea;
}
</style>
