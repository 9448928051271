<template>
	<div class="TheFooter">
		<footer class="text-center">
			<p class="text-base">Copyright © 2022, <a href="/" class="underline">Leaping Gibbon Sdn. Bhd.</a></p>
			<a href="/" class="underline">201901032114  (1341444-T)</a>
		</footer>
	</div>
</template>


<script>
export default {
	name: 'TheFooter',
}
</script>


<style scoped lang="less">
.TheFooter {
	display: flex;
	justify-content: center;
	padding: 20px;
	background-color: #393939;
	color: #fff;
}
</style>
