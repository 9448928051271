<template>
    <section
        class="SectionContactUs"
        data-section-label="Contact Us"
        data-root-section
        data-anchor-id="contact-us"
        id="contact-us"
        
    >
        <div class="container-max flex flex-col justify-center items-center">
            <div
                class="contactus-content bg-white min-w-full flex flex-col justify-center items-center py-10"
            >
                <h1 class="section-title text-6xl font-bebas">Contact Us</h1>
                <div class="rectangle-blue my-6"></div>
                <div class="text-center px-3 mx-10 container max-w-4xl">
                    <p>
                        Your child's first experience at school is important to
                        build a strong foundation for their future learning in
                        life. Our name, P.I.T.H. , shows our commitment to provide
                        the right learning environment for your child - warmth,
                        safe, inspiring and enriching. Our teaching philosophy
                        that adopts a holistic approach, learning through play
                        and nature-inspired will spark imagination, character
                        building and critical thinking of your child. If you
                        have any enquiries, please reach out to us and our team
                        will be delighted to address them.
                    </p>
                </div>
            </div>

            <GoogleMap class="w-full" />

            <div
                class="contact-detail flex flex-col justify-center items-center m-10"
            >
                <div
                    class="contact-address flex flex-col justify-center items-center text-center"
                >
                    <h1 class="font-bold">Address</h1>
                    <address class="not-italic">
                        3, Persiaran Kampar, 30250, Ipoh, Perak, Malaysia
                    </address>
                </div>
                <div
                    class="contact-email flex flex-col justify-center items-center mt-6"
                >
                    <h1 class="font-bold">Email</h1>
                    <a
                        class="hover:underline"
                        href="mailto:enquiries@podinthehood.com"
                        >enquiries@podinthehood.com</a
                    >
                </div>
                <div
                    class="contact-mobile-number flex flex-col justify-center items-center mt-6"
                >
                    <h1 class="font-bold">Mobile Number</h1>
                    <a class="hover:underline" href="tel:60192202883"
                        >+6019 220 2883</a
                    >
                </div>
                <div
                    class="contact-social-platform flex justify-center items-center mt-6"
                >
                    
                    <a class="mr-4" href="https://www.instagram.com/pith_kindergarten/" target="_blank"
                        ><img src="../../assets/ig.png" alt="HTML tutorial" style="width:42px;height:42px;"></a>
                        
                        <a href="https://www.facebook.com/PITH.Kindergarten/" target="_blank"
                        ><img src="../../assets/fb.png" alt="HTML tutorial" style="width:42px;height:42px;"></a>
                </div>
              
            </div>
            <hr class="mb-4 w-full h-full border-gray-500" />

            <h1 class="text-center text-2xl mb-10 font-bold">
                Feel free to contact us!
            </h1>

            <form
                class="inquiry-form container max-w-4xl px-5 relative"
                @submit.prevent="handleContactFormSubmit"
            >
                <BaseSpinner
                    v-if="isLoading"
                    class="h-full absolute m-auto top-0 left-0 w-full z-50"
                />

                <div
                    v-if="isSubmitSuccess"
                    class="mb-10 text-center py-6 bg-white"
                >
                    <h2 class="text-lg my-10">
                        <div class="flex justify-center items-center">
                            <div class="circle-loader">
                                <div class="checkmark draw"></div>
                            </div>
                            <div class="ml-6">
                                Your message has been submitted.
                                <br />We will be in touch soon.
                            </div>
                        </div>
                    </h2>
                </div>

                <div
                    v-else
                    class="flex flex-col justify-center"
                    :class="{
                        'opacity-25': isLoading,
                    }"
                >
                    <div class="inquiry-name-phone flex flex-row mb-5">
                        <div class="inquiry-name flex flex-col flex-grow mr-5">
                            <label class="ml-1 mb-1" for="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                v-model="formData.name"
                                placeholder="E.g. Fedrick Low"
                            />
                        </div>

                        <div class="flex flex-col flex-grow">
                            <label class="ml-1 mb-1" for="phoneNumber"
                                >Phone Number</label
                            >
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                v-model="formData.phone_number"
                                maxlength="12"
                                placeholder="E.g. 0123456789"
                            />
                        </div>
                    </div>

                    <label class="ml-1 mb-1" for="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        class="mb-5"
                        v-model="formData.email"
                        placeholder="E.g. name@email.com"
                    />

                    <label class="ml-1 mb-1" for="message">Message</label>
                    <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        v-model="formData.message"
                        placeholder="Please type your message here"
                    ></textarea>

                    <VueRecaptcha
                        class="mx-auto mt-6"
                        sitekey="6LcsC8EZAAAAALwNQ_FVipXDMTAwwGn1ifW89USs"
                        @verify="formData.recaptcha = $event"
                    />

                    <button type="submit" class="submit-btn">Submit</button>
                </div>
            </form>
        </div>
    </section>
</template>


<script>
import VueRecaptcha from "vue-recaptcha";
import GoogleMap from "@/components/GoogleMap.vue";
const axios = require("axios");

export default {
    name: "SectionContactUs",
    components: {
        VueRecaptcha,
        GoogleMap,
    },
    data() {
        return {
            isLoading: false,
            formData: {
                name: "",
                email: "",
                phone_number: "",
                message: "",
                recaptcha: "",
            },
            isSubmitSuccess: false,
        };
    },
    methods: {
        handleContactFormSubmit(event) {
            console.log("submiting...");
            this.isLoading = true;

            axios
                .post(
                    "https://podinthehood.com/cms/public/podinthehood/items/contact_us_submissions",
                    this.formData
                )
                .then((response) => {
                    this.isLoading = false;
                    console.log("Form submission done");
                    console.log(response);
                    this.isSubmitSuccess = true;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.error("Error in form submission. Err = ", error);
                });
        },
    },
    // async created () {
    // 	const bannerContent = (await this.$directus.getItems('hero_banner', {
    // 		fields: '*,banner_image.data.full_url',
    // 	})).data;

    // 	this.isLoading = false;
    // 	this.bannerContent = bannerContent;
    // },
};
</script>


<style lang="less" scoped>
.SectionContactUs {
    --bannerMinHeight: 450px;
    min-height: var(--bannerMinHeight);
    background-color: #eceaea;
}

input,
textarea {
    @apply py-2 px-2;
}

.submit-btn {
    background-color: #393939;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin: 20px auto;
    width: 150px;
    height: 35px;
    font-size: 16px;
    cursor: pointer;
    @apply font-bold;
}

@media (min-width: 280px) and (max-width: 480px) {
    .section-title {
        @apply text-4xl leading-none;
    }
    .contactus-content {
        p {
            @apply text-xs mx-4;
        }
    }
    .inquiry-name-phone {
        @apply flex-col;
    }
    .inquiry-name {
        @apply mr-0 mb-5;
    }
    form {
        label {
            @apply text-sm;
        }
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    .inquiry-form {
        @apply w-9/12;
    }
    .inquiry-name-phone {
        @apply flex-col;
    }
    .inquiry-name {
        @apply mr-0 mb-5;
    }
    form {
        label {
            @apply text-sm;
        }
    }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
    .inquiry-form {
        @apply w-9/12;
    }
    .inquiry-name-phone {
        @apply flex-col;
    }
    .inquiry-name {
        @apply mr-0 mb-5;
    }
    form {
        label {
            @apply text-sm;
        }
    }
}
</style>
