<template>
    <section
        class="SectionAdmission"
        data-section-label="Admission"
        data-root-section
        data-anchor-id="admission"
    >
        <div class="container-max admission-wrapper min-h-inherit flex">
            <div class="w-full image-wrapper image">
                <!-- <img src="../../assets/boy-spec.png" style="height: 100%;"/> -->
            </div>
            <div
                class="admission-content w-full text-white programmes-container flex flex-col items-center justify-center"
            >
                <h1 class="admission-title text-6xl font-bebas">Admission</h1>
                <div class="rectangle-orange my-6"></div>
                <div class="text-center px-3 mx-10 admission-description mb-6">
                    <p>We strive to make admission process as smooth as possible for you and your child.</p>
                </div>

                <router-link class="font-bold" to="/admission">
                    <button class="button">Know More</button>
                </router-link>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: "SectionAdmission",
    data() {
        return {
            // bannerContent: [],
            isLoading: true,
        };
    },
    // async created () {
    // 	const bannerContent = (await this.$directus.getItems('hero_banner', {
    // 		fields: '*,banner_image.data.full_url',
    // 	})).data;

    // 	this.isLoading = false;
    // 	this.bannerContent = bannerContent;
    // },
};
</script>


<style lang="less" scoped>
.SectionAdmission {
    --bannerMinHeight: 450px;
    min-height: var(--bannerMinHeight);
    background-color: #eceaea;
}
.image-wrapper {
    width: 100%;
    height: auto;
    // background-image: url("../../assets/boy-spec.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.image {
    background-image: url("../../assets/admission-section.jpg");
}

.admission-content {
    background-color: #393939;
}
@media (min-width: 280px) and (max-width: 480px) {
    .image-wrapper {
        height: 250px;
    }
    .admission-wrapper {
        @apply flex-col mt-4;
    }
    .admission-content {
        @apply p-6;
    }

    .admission-title {
        @apply text-4xl leading-none;
    }
    .rectangle-orange {
        @apply my-4;
    }
    .admission-description {
        @apply mx-0 mb-4 text-xs;
    }
    .button {
        padding: 4px 16px;
    }
    .image-wrapper {
        width: 100%;
        height: 250px;
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    
    .admission-wrapper {
        @apply flex-col mt-4;
    }
    .admission-content {
        @apply p-6;
    }

    .admission-title {
        @apply text-4xl leading-none;
    }
    .rectangle-orange {
        @apply my-4;
    }
    .admission-description {
        @apply mx-0 mb-4 text-xs;
    }
    .button {
        padding: 4px 16px;
    }
    .image-wrapper {
        width: 100%;
        height: 210px;
        
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
   
    .admission-wrapper {
        @apply flex-col mt-4;
    }
    .admission-content {
        @apply p-6;

    }

    .admission-title {
        @apply text-4xl leading-none;
    }
    .rectangle-orange {
        @apply my-4;
    }
    .admission-description {
        @apply mx-0 mb-4 text-xs;
    }
    .button {
        padding: 4px 16px;
    }
    .image-wrapper {
        width: 100%;
        height: 210px;
        background-position: 50% 90%;
    }
}
</style>
