<template>
	<div class="GoogleMap">
		<div class="iframe-wrapper">
			<iframe 
			class="border-0 w-full h-full"src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3977.0526982329047!2d101.09767161476194!3d4.584562796669332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31caec44011284df%3A0xffb3802f64840d09!2s3%2C%20Persiaran%20Kampar%2C%20Taman%20Gopeng%2C%2030250%20Ipoh%2C%20Negeri%20Perak%2C%20Malaysia!5e0!3m2!1sen!2suk!4v1599455690560!5m2!1sen!2suk" allowfullscreen></iframe>
		</div>
	</div>
</template>


<script>
export default {
	name: 'GoogleMap',
}
</script>


<style scoped lang="less">
.GoogleMap {
	
}
.iframe-wrapper {
	min-height: 300px;
	max-height: 500px;
	height: 56.25vw;
}
</style>
