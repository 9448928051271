<template>
    <section
        class="SectionPhilosophy bg-gray-200"
        data-section-label="Philosophy"
        data-root-section
        data-anchor-id="philosophy"
    >
        <div class="container-max phil-container flex flex-col items-center min-h-inherit">
            <!-- <h1 class="text-white">Section Philosophy</h1> -->
            <div class="our-story flex bg-white w-full">
                <div class="w-full image-wrapper image-story">
                    <!-- <img src="../../assets/kid-with-block.png" class /> -->
                </div>
                <div
                    class="our-story-content-container w-full flex flex-col items-center justify-center py-6"
                >
                    <h1 class="title-container font-bebas">Our Story</h1>
                    <div class="rectangle-blue my-6"></div>
                    <p
                        class="text-center px-3 mx-10"
                    >Pod in the Hood started as a humble children bookshop, the purveyor of inspiring children books and toys, in Ipoh in early 2020 by a husband-and-wife team. Staying true to the purpose of Pod in the Hood, they strive to create a sanctuary for young children and be the 'P.I.T.H.' of inspiration, development and nurture. Hence, P.I.T.H. Kindergarten is developed to bring warmth and light to the little ones, making learning a fun and fulfilling one.</p>
                </div>
            </div>
            <div class="our-phil flex bg-white w-full">
                <div
                    class="our-phil-content-container w-full flex flex-col items-center justify-center py-6"
                >
                    <h1 class="title-container font-bebas">Our Philosophy</h1>
                    <div class="rectangle-blue my-6"></div>
                    <div class="our-phil-description text-center px-3 mx-10">
                        <p>P.I.T.H. kindergarten believes that learning can be fun and intriguing. We have incorporated Finnish Pedagogy in our teaching approach to support the holistic development and learning through play to develop the socio-emotional and critical thinking of a child. Your child’s first experience and memory at school is significant to how they will approach learning in future. At P.I.T.H., our goal is to provide early childhood education that motivates curiosity in learning while preparing them for the academic journey. We also believe that the development of social, life skills and values are equally important as part of the learning process at P.I.T.H.</p>
                        <p
                            class="my-5"
                        >Our kindergarten facilities are designed to encourage learning through not only classroom-based, but also other learning spaces outside of the classroom to promote movement and activity, and keeping the children engaged and stimulated for optimal development.</p>
                        <div class="ul-content my-5">
                            P.I.T.H. kindergarten are carefully curated to ensure the following:
                            <ul class="my-4 list-disc font-bold list-disc list-inside">
                                <li class="mb-2 capitalize">encourage movement and activity</li>
                                <li
                                    class="mb-2 capitalize"
                                >stay in touch with mother nature inside and outside of the classroom</li>
                                <li
                                    class="mb-2 capitalize"
                                >harmony, safe and clean learning environment</li>
                                <li class="mb-2 capitalize">inspire imagination and creativity</li>
                                <li class="capitalize">create fun and happy childhood memories</li>
                            </ul>It is our believes and philosophy that make P.I.T.H. Kindergarten a warmth and embracing second home to inspire your child's learning.
                        </div>
                    </div>
                </div>
                <div class="w-full image-wrapper image-phil">
                    <!-- <img src="../../assets/scrable.png" class /> -->
                </div>
            </div>
            <div class="our-values flex bg-white w-full">
                <div class="w-full image-wrapper image-value">
                    <!-- <img src="../../assets/story-reading.png" class /> -->
                </div>
                <div
                    class="our-values-content-container w-full flex flex-col items-center justify-center py-6"
                >
                    <h1 class="title-container font-bebas">Our Values</h1>
                    <div class="rectangle-blue my-6"></div>
                    <div class="text-center px-3 mx-10">
                        <ul>
                            <li class="text-2xl font-bold">Passion</li>
                            <li class="my-3 text-2xl font-bold">Integrity</li>
                            <li class="my-3 text-2xl font-bold">Tolerance</li>
                            <li class="my-3 text-2xl font-bold">Humanity</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: "SectionPhilosophy",
    data() {
        return {
            // bannerContent: [],
            isLoading: true,
        };
    },
    // async created () {
    // 	const bannerContent = (await this.$directus.getItems('hero_banner', {
    // 		fields: '*,banner_image.data.full_url',
    // 	})).data;

    // 	this.isLoading = false;
    // 	this.bannerContent = bannerContent;
    // },
};
</script>


<style lang="less" scoped>
.SectionPhilosophy {
    --bannerMinHeight: 450px;
    min-height: var(--bannerMinHeight);
    background-color: #eceaea;
}
.our-story-content-container,
.our-phil-content-container,
.our-values-content-container {
    min-height: 355px;
}
.title-container {
    font-size: 54px;
}
.image-wrapper {
    width: 100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.image-story {
    background-image: url(../../assets/kid-with-block.png);
}
.image-phil {
    background-image: url(../../assets/scrable.png);
}
.image-value {
    background-image: url(../../assets/story-reading.png);
}

@media (min-width: 280px) and (max-width: 480px) {
    .our-story {
        @apply flex-col;
        @apply mt-2;
        p {
            @apply text-xs mx-4;
        }
    }

    .our-values {
        @apply flex-col;
        p,
        ul > li {
            @apply text-base mx-4;
        }
    }

    .rectangle-blue {
        @apply my-4;
    }
    .our-phil {
        @apply flex-col-reverse;
        .our-phil-description {
            @apply mx-0;
        }
        p,
        .ul-content {
            @apply text-xs mx-4;
        }
    }
    .image-wrapper {
        width: 100%;
        height: 250px;
    }
    .title-container {
        @apply text-4xl leading-none;
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    .image-wrapper {
        width: 100%;
        height: 250px;
    }
    .title-container {
        font-size: 54px;
        @apply leading-none;
    }
    .our-story {
        @apply flex-col;
        @apply mt-2;
    }
    .our-phil {
        @apply flex-col-reverse;
        .our-phil-description {
            @apply mx-0;
        }
        p,
        .ul-content {
            @apply px-3 mx-10 mx-4;
        }
    }

    .our-values {
        @apply flex-col;
        p,
        ul > li {
            @apply text-lg mx-4;
        }
    }

    .rectangle-blue {
        @apply my-4;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .image-wrapper {
        width: 100%;
        height: 250px;
    }
    .title-container {
        font-size: 54px;
        @apply leading-none;
    }
    .our-story {
        @apply flex-col;
        @apply mt-2;
    }
    .our-phil {
        @apply flex-col-reverse;
        .our-phil-description {
            @apply mx-0;
        }
        p,
        .ul-content {
            @apply px-3 mx-10 mx-4;
        }
    }

    .our-values {
        @apply flex-col;
        p,
        ul > li {
            @apply text-lg mx-4;
        }
    }
    .rectangle-blue {
        @apply my-4;
    }
}
</style>
