<template>
	<div id="app" >
		<TheNavbar />
		<router-view />
		<TheFooter/>
	</div>
</template>

<script>
import TheNavbar from '@/components/singletons/TheNavbar.vue';
import TheFooter from '@/components/singletons/TheFooter.vue';

import '@/styles/global.less';

export default {
	components: {
		TheNavbar,
		TheFooter,
	},

}
</script>
