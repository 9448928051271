<template>
    <section
        class="SectionProgrammesAndWorkshop"
        data-section-label="Programmes & Workshop"
        data-root-section
        data-anchor-id="programmes-and-workshop"
    >
        <div class="container-max flex min-h-inherit pnw-wrapper">
            <div
                class="pnw-container w-full text-white flex flex-col items-center justify-center"
            >
                <h1 class="pnw-title text-6xl font-bebas text-center">
                    Programme and Workshop
                </h1>
                <div class="rectangle-orange my-6"></div>
                <div class="pnw-description text-center px-3 mx-10 mb-6">
                    <p>
                        We provide enrichment programme, which aim to motivate
                        children to explore the world around them and develop
                        their social and life skills that typically not covered
                        in a normal school curriculum.
                    </p>
                </div>

                <div class="font-bold">
                    <button @click="scrollToContactUs()" class="button">
                        Contact Us
                    </button>
                </div>
            </div>
            <div class="w-full image-wrapper image">
                <!-- <img src="../../assets/little-boy.png" class="min-h-full" /> -->
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: "SectionProgrammesAndWorkshop",
    data() {
        return {
            // bannerContent: [],
            isLoading: true,
        };
    },
    methods: {
        scrollToContactUs() {

            document.querySelector("#contact-us").scrollIntoView({
                behavior: "smooth",
            });
        },
    },
    // async created () {
    // 	const bannerContent = (await this.$directus.getItems('hero_banner', {
    // 		fields: '*,banner_image.data.full_url',
    // 	})).data;

    // 	this.isLoading = false;
    // 	this.bannerContent = bannerContent;
    // },
};
</script>


<style lang="less" scoped>
.SectionProgrammesAndWorkshop {
    --bannerMinHeight: 450px;
    min-height: var(--bannerMinHeight);
    background-color: #eceaea;
}
.image-wrapper {
    width: 100%;
    height: auto;
    // background-image: url("../../assets/little-boy.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.image {
    background-image: url("../../assets/little-boy.png");
}
.pnw-container {
    background-color: #393939;
}

@media (min-width: 280px) and (max-width: 480px) {
    .pnw-wrapper {
        flex-direction: column-reverse;
        @apply mt-4;
    }
    .pnw-container {
        @apply p-6;
    }
    .pnw-title {
        @apply text-4xl leading-none;
    }
    .rectangle-orange {
        @apply my-4;
    }
    .pnw-description {
        @apply mx-0 mb-4 text-xs;
    }
    .button {
        padding: 4px 16px;
    }
    .image-wrapper {
        width: 100%;
        height: 250px;
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    .pnw-wrapper {
        flex-direction: column-reverse;
        @apply mt-4;
    }
    .image-wrapper {
        width: 100%;
        height: 240px;
    }
    .pnw-container {
        @apply p-6;
    }
    .pnw-title {
        @apply text-4xl leading-none;
    }
    .rectangle-orange {
        @apply my-4;
    }
    .pnw-description {
        @apply mx-0 mb-4 text-xs;
    }
    .button {
        padding: 4px 16px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .pnw-wrapper {
        flex-direction: column-reverse;
        @apply mt-4;
    }
    .image-wrapper {
        width: 100%;
        height: 240px;
    }
    .pnw-container {
        @apply p-6;
    }
    .pnw-title {
        @apply text-4xl leading-none;
    }
    .rectangle-orange {
        @apply my-4;
    }
    .pnw-description {
        @apply mx-0 mb-4 text-xs;
    }
    .button {
        padding: 4px 16px;
    }
}
</style>
