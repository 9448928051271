import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/programmes-and-workshop',
		name: 'Programmes And Workshop',
		component: () => import(/* webpackChunkName: "ProgrammesAndWorkshop" */ '../views/ProgrammesAndWorkshop.vue'),
	},
	{
		path: '/admission',
		name: 'Admission',
		component: () => import(/* webpackChunkName: "Admission" */ '../views/Admission.vue'),
	},
	{
		path: '/404',
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
	},
	
	{
		path: '*',
		redirect: '/404',
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
