import { render, staticRenderFns } from "./BaseSpinner.vue?vue&type=template&id=feda0ab4&functional=true"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6._iyputc2ruckis4av77ubvklewu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports