<template>
    <section class="BaseSwiper">
        <div class="swiper-container" :class="containerClass" ref="container">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>

            <div v-if="hasPagination" class="swiper-pagination" ref="pagination"></div>

            <!-- <div v-if="hasNavButtons" class="swiper-button-prev" ref="btnPrev"></div>
            <div v-if="hasNavButtons" class="swiper-button-next" ref="btnNext"></div>-->
        </div>
        <div v-if="hasNavButtons" class="swiper-button-prev" ref="btnPrev"></div>
        <div v-if="hasNavButtons" class="swiper-button-next" ref="btnNext"></div>
    </section>
</template>


<script>
// import Swiper from 'swiper';
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

Swiper.use([
    Navigation,
    // Pagination,
]);

export default {
    name: "BaseSwiper",
    props: {
        hasPagination: {
            type: Boolean,
            default: false,
        },
        hasNavButtons: {
            type: Boolean,
            default: false,
        },
        swiperOptions: {
            type: Object,
            default: () => ({}),
        },
        syncToVMs: {
            type: Array,
            default: () => [],
        },
        containerClass: {
            type: String,
            default: "",
        },
        enableCustomObserver: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.initSwiper();

        /*
			Note: this is a workaround for the issue of refs not reactive,
			causing syncToVMs array to ALWAYS be [ undefined ].
			In this solution, we force its parent to update, and it will populate syncToVMs with the correct VM instance.
		*/
        // this.$parent.$forceUpdate();

        // this.$nextTick(() => {
        // 	// perform sync 1 time on mounted to cater for situation where swiper option "initialSlide" was used.
        // 	this.performSyncToVms();

        // 	// to fix autoHeight not updating upon content height change
        // 	if (this.enableCustomObserver) this.initMutationObserver();
        // });
    },
    computed: {
        swiperOptionsMerged() {
            return {
                // swiper defaults
                threshold: 20,
                centeredSlides: true,
                watchOverflow: true,
                slideToClickedSlide: true,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                // options from props
                ...this.swiperOptions,
            };
        },
    },
    methods: {
        initSwiper() {
            if (this.swiper && !this.swiper.destroyed) {
                this.swiper.destroy();
            }

            const swiperEvents = this.getSwiperEventObject();
            this.swiper = new Swiper(this.$refs.container, {
                ...this.swiperOptionsMerged,

                pagination: {
                    el: this.$refs.pagination || null,
                    dynamicBullets: true,
                    dynamicMainBullets: 5,
                },
                navigation: {
                    nextEl: this.$refs.btnNext || null,
                    prevEl: this.$refs.btnPrev || null,
                },
                on: {
                    ...swiperEvents,
                },
            });

            this.swiper.on("slideChange", this.performSyncToVms);
        },
        getSwiperEventObject() {
            /*
				Wraps events by swiper.js in a function that always give this swiper instance as first arg,
				and second arg is what swiper naturally give.
				
				Component usage example:
				<BaseSwiper
					class="tw-pt-4"
					@transitionEnd="handleTransitionEnd"
					@submit="handleSubmit"
				> ... </BaseSwiper>
				
				@transitionEnd event will be wrapped and @submit listener will be ignored.
				In `handleTransitionEnd (swiper, event) { ... }`, this will always work like normal (Vue instance).
			*/

            const SWIPER_EVENT_LIST = [
                "init",
                "beforeDestroy",
                "slideChange",
                "slideChangeTransitionStart",
                "slideChangeTransitionEnd",
                "slideNextTransitionStart",
                "slideNextTransitionEnd",
                "slidePrevTransitionStart",
                "slidePrevTransitionEnd",
                "transitionStart",
                "transitionEnd",
                "touchStart",
                "touchMove",
                "touchMoveOpposite",
                "sliderMove",
                "touchEnd",
                "click",
                "tap",
                "doubleTap",
                "imagesReady",
                "progress",
                "reachBeginning",
                "reachEnd",
                "toEdge",
                "fromEdge",
                "setTranslate",
                "setTransition",
                "resize",
                "observerUpdate",
                "beforeLoopFix",
                "loopFix",
            ];

            const swiperEvents = {};

            Object.keys(this.$listeners).forEach((listenerKey) => {
                if (SWIPER_EVENT_LIST.includes(listenerKey)) {
                    swiperEvents[listenerKey] = (arg) => {
                        this.$listeners[listenerKey](this.swiper, arg);
                    };
                }
            });

            return swiperEvents;
        },

        performSyncToVms() {
            // console.log('this.syncToVMs = ', this.syncToVMs);
            this.syncToVMs.forEach((vm) => {
                // console.log('vm = ', vm);
                if (!vm) {
                    console.warn(
                        "syncToVMs array prop seems to have a value but it is not a Vue instance. This might be due to $refs being not reactive."
                    );
                    return;
                }
                vm.swiper?.slideTo(this.swiper.activeIndex);
                // vm.swiper?.slideTo(this.swiper.activeIndex, 0); // Sliding without animation
            });
        },

        updateAutoHeight() {
            if (this.swiper && !this.swiper.destroyed) {
                try {
                    this.swiper.updateAutoHeight();
                } catch (err) {}
            }
        },

        initMutationObserver() {
            const MutationObserver =
                window.MutationObserver ||
                window.WebKitMutationObserver ||
                window.MozMutationObserver;
            const debounced_updateAutoHeight = _.debounce(
                this.updateAutoHeight,
                120
            );

            const observer = new MutationObserver((mutations) => {
                // console.log(`mutations = `, mutations); // MutationRecord
                mutations.forEach((mutation) => {
                    if (mutation.type === "attributes") {
                        // console.log('mutation = ', mutation);
                        // console.log(`The \`${mutation.attributeName}\` attribute was modified.`);
                        debounced_updateAutoHeight();
                    }
                });
            });

            observer.observe(this.$el, {
                attributes: true,
                // childList: true,
                // characterData: true,
                subtree: true,
            });
        },
    },
};
</script>

<style lang="less">
.BaseSwiper {
    // width: 100%;
    max-width: 100vw;

    .swiper-container {
        width: 100%;
        // width: 900px;
    }
    // .swiper-wrapper {
    // 	box-sizing: border-box;
    // }

    .swiper-button-prev,
    .swiper-button-next {
        color: white;
    }
    .swiper-button-prev {
        left: 1.5rem;
    }
    .swiper-button-next {
        right: 1.5rem;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .BaseSwiper {
        .swiper-button-prev {
            left: 0.5rem;
        }
        .swiper-button-next {
            right: 0.5rem;
        }
    }
}
</style>
